import React from "react"
import PageRoot from "../components/PageRoot"
import { graphql, Link } from "gatsby"
import { devLog, renderCarouselItem } from "../utils"
import EventureGrid from "../components/EventureGrid"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { useTranslation } from "react-i18next"
import wallpaper from "../../static/assets/images/hexagon-bg.jpg"
import { Button } from "antd"
import GenericHeader from "../components/GenericHeader"
import { useAuth0 } from "@auth0/auth0-react"

const AllCategoryPage = ({ data }) => {
  const { t } = useTranslation()
  const categoryList = data.eventure.categories

  devLog({ categoryList })

  return (
    <PageRoot>
      <GenericHeader
        data={{
          title: "Categories",
          subtitle: "Learn From the best, die like the rest.",
          wallpaper: wallpaper
        }}
      />
      <div className="category-all-wrapper">
        <ContainerWithPadding align={"start"}>
          <EventureGrid dataSource={categoryList} renderItem={renderCarouselItem} />
        </ContainerWithPadding>
      </div>
    </PageRoot>
  )
}

export const query = graphql`
  query allCategories {
    eventure {
      categories(order_by: { name: asc }, where: { _not: { parent: {} } }) {
        ...PreviewCardCategory
      }
    }
  }
`

export default AllCategoryPage
